import { GetSafeleaseLocationByTenantResponse, SafeleaseUnitMatchingData } from "@safelease/service-utilities";
import { create } from "zustand";
import { MIXPANEL_EVENTS } from "../utils/mixpanel";

export enum PrivatePolicySubmissionStepType {
  FIND_LOCATION = "findLocation",
  FIND_UNIT = "findUnit",
  SAFELEASE_SUPPLEMENTAL_COVERAGE = "safeleaseSupplementalCoverage",
  POLICY_DETAILS = "policyDetails",
}

// id is null if the location is coming soon and the user manually inputs the unit number
export type MatchedUnit = (SafeleaseUnitMatchingData | { id: null; name: string }) & {
  matchedBySafelease: boolean;
};

type PrivatePolicyFormStore = {
  privatePolicyFormRef: React.RefObject<HTMLDivElement> | null;
  setPrivatePolicyFormRef: (privatePolicyFormRef: React.RefObject<HTMLDivElement> | null) => void;
  currentPrivatePolicySubmissionStep: PrivatePolicySubmissionStepType;
  setCurrentPrivatePolicySubmissionStep: (currentPrivatePolicySubmissionStep: PrivatePolicySubmissionStepType) => void;
  needHelpModalOpen: boolean;
  setNeedHelpModalOpen: (needHelpModalOpen: boolean) => void;
  hidePrivatePolicyFormHeader: boolean;
  setHidePrivatePolicyFormHeader: (hidePrivatePolicyFormHeader: boolean) => void;
  matchedLocation: GetSafeleaseLocationByTenantResponse | null;
  setMatchedLocation: (matchedLocation: GetSafeleaseLocationByTenantResponse | null) => void;
  matchedUnit: MatchedUnit | null;
  setMatchedUnit: (matchedUnit: MatchedUnit | null) => void;
  handleCancel: (mixpanelTrack: (eventName: string, eventProperties: any) => void, navigate: (path: string) => void) => void;
  clearPrivatePolicyFormStore: () => void;
};

export const usePrivatePolicyFormStore = create<PrivatePolicyFormStore>()((set, get) => ({
  privatePolicyFormRef: null,
  setPrivatePolicyFormRef: (privatePolicyFormRef) => {
    set({ privatePolicyFormRef });
  },
  currentPrivatePolicySubmissionStep: PrivatePolicySubmissionStepType.FIND_LOCATION,
  setCurrentPrivatePolicySubmissionStep: (currentPrivatePolicySubmissionStep) => {
    set({ currentPrivatePolicySubmissionStep });
  },
  needHelpModalOpen: false,
  setNeedHelpModalOpen: (needHelpModalOpen) => {
    set({ needHelpModalOpen });
  },
  hidePrivatePolicyFormHeader: false,
  setHidePrivatePolicyFormHeader: (hidePrivatePolicyFormHeader) => {
    set({ hidePrivatePolicyFormHeader });
  },
  matchedLocation: null,
  setMatchedLocation: (matchedLocation) => {
    set({ matchedLocation });
  },
  matchedUnit: null,
  setMatchedUnit: (matchedUnit) => {
    set({ matchedUnit });
  },
  handleCancel: (mixpanelTrack, navigate) => {
    if (window.confirm("Are you sure you want to cancel? You will lose your progress.")) {
      mixpanelTrack(MIXPANEL_EVENTS.PRIVATE_POLICY_OPT_OUT_FORM_CANCELLED, {
        step: get().currentPrivatePolicySubmissionStep,
        location: get().matchedLocation,
        unit: get().matchedUnit,
      });
      get().clearPrivatePolicyFormStore();
      navigate("/");
    }
  },
  clearPrivatePolicyFormStore: () => {
    set({
      privatePolicyFormRef: null,
      currentPrivatePolicySubmissionStep: PrivatePolicySubmissionStepType.FIND_LOCATION,
      needHelpModalOpen: false,
      hidePrivatePolicyFormHeader: false,
      matchedLocation: null,
      matchedUnit: null,
    });
  },
}));
